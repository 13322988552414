/* eslint-disable no-template-curly-in-string */
import { esErrors } from './error-codes/es-errors';
import { esBalance } from '../pages/balance/locales/es-balance';

const es = {
  global: {
    table: {
      dense: 'Vista comprimida',
      rows: 'Filas por página',
      noData: 'No hay datos que mostrar',
    },
    buttons: {
      cancel: 'Cancelar',
      accept: 'Aceptar',
      delete: 'Borrar',
      save: 'Guardar',
      saveChanges: 'Guardar cambios',
      understood: 'Ok, enterado',
      finish: 'Finalizar',
      back: 'Anterior',
      next: 'Siguiente',
      records: 'Ver Historial',
      edit: 'Editar',
      eliminate: 'Eliminar',
      register: 'Registrar',
      confirm: 'Confirmar',
      add: 'Agregar',
      select: 'Seleccionar',
      selectAll: 'Seleccionar todo',
      unselectAll: 'Dejar de seleccionar',
      search: 'Buscar',
      create: 'Crear',
      goBack: 'Regresar',
      remove: 'Quitar',
      continue: 'Continuar',
      showStaff: 'Ver Staff',
      gotIt: 'Entendido',
      seeMore: 'Ver más',
      seeLess: 'Ver menos',
      loadMore: 'Cargar más',
      activate: 'Activar',
      deactivate: 'Desactivar',
      close: 'Cerrar',
      ready: 'Listo',
      uploadAnother: 'Sube otro archivo',
      viewDetails: 'Ver detalles',
      start: 'Comenzar',
    },
    placeholders: {
      search: 'Buscar',
      searchAll: 'Buscar por folio, nombre, correo, no. de reservación o ID',
      name: 'Nombre(s)',
      lastName: 'Apellido(s)',
      fullName: 'Nombre completo',
      email: 'Correo electrónico',
      password: 'Contraseña',
      cards: 'Tarjetas',
      buttons: 'Botones',
    },
    labels: {
      registered: 'Registrado',
      registered2: 'Registrados',
      notRegistered: 'Sin registro',
      noData: 'Sin datos',
      noDate: 'Sin fecha',
      event: 'Evento',
      events: 'Eventos',
      dates: 'Fechas',
      date: 'Fecha',
      time: 'Hora',
      venue: 'Lugar',
      venue2: 'Recinto',
      duplicate: 'Duplicado',
      active: 'Activo',
      inactive: 'Inactivo',
      archived: 'Archivado',
      deleted: 'Borrado',
      finished: 'Finalizado',
      label: 'Etiqueta',
      eventId: 'ID del evento',
      noLabel: 'Sin etiqueta',
      lastDay: 'Último día',
      removeTag: 'Quitar etiqueta',
      selected: 'Seleccionado',
      notSelected: 'No seleccionado',
      selectedAll: 'Seleccionados',
      notSelectedAll: 'No seleccionados',
      soldOut: '¡Vendido!',
      noTickets: 'No hay boletos registrados',
      new: 'Nuevo',
      site: 'Sitio',
      createdAt: 'Fecha de creación',
      status: 'Estado',
      ticket: 'Boleto',
      address: 'Dirección',
      settings: 'Ajustes',
      and: 'y',
      deactivated: 'Desactivado',
      undetermined: 'Sin determinar',
      banks: 'Bancos',
      promotion: 'Promoción',
      duration: 'Duración',
      setting: 'Configuración',
      bank: 'Banco',
      quantity: 'Cantidad',
      updatedAt: 'Fecha de modificación',
      createdBy: 'Creado por',
      cards: 'Tarjetas',
      card: 'Tarjeta',
      cardName: 'Nombre de Tarjeta',
      addBank: 'Agregar banco',
      starts: 'Inicia',
      ends: 'Termina',
      tickets: 'Boletos',
      booking: 'Reservación',
      name: 'Nombre',
      notAvailable: 'No disponible',
      sales: 'Ventas',
      noOptions: 'Sin opciones',
      not_published: 'No publicado',
      user: 'Usuario',
    },
  },
  ...esBalance,
  ...esErrors,
};

export default es;
