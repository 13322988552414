/* eslint-disable no-template-curly-in-string */
import { enErrors } from './error-codes/en-errors';
import { enBalance } from '../pages/balance/locales/en-balance';

const en = {
  global: {
    table: {
      dense: 'Compact view',
      rows: 'Rows per page',
      noData: 'No data to show',
    },
    buttons: {
      cancel: 'Cancel',
      accept: 'Accept',
      delete: 'Delete',
      save: 'Save',
      saveChanges: 'Save changes',
      understood: 'Ok, understood',
      finish: 'Finish',
      back: 'Back',
      next: 'Next',
      records: 'View History',
      edit: 'Edit',
      eliminate: 'Eliminate',
      register: 'Register',
      confirm: 'Confirm',
      add: 'Add',
      select: 'Select',
      selectAll: 'Select all',
      unselectAll: 'Unselect all',
      search: 'Search',
      create: 'Create',
      goBack: 'Go back',
      remove: 'Remove',
      continue: 'Continue',
      showStaff: 'Show Staff',
      gotIt: 'Got it',
      seeMore: 'See more',
      seeLess: 'See less',
      loadMore: 'Load more',
      activate: 'Activate',
      deactivate: 'Deactivate',
      close: 'Close',
      ready: 'Ready',
      uploadAnother: 'Upload another file',
      viewDetails: 'View details',
      start: 'Start',
    },
    placeholders: {
      search: 'Search',
      searchAll: 'Search by folio, name, email, reservation number or ID',
      name: 'Name(s)',
      lastName: 'Last name(s)',
      fullName: 'Full name',
      email: 'Email',
      password: 'Password',
      cards: 'Cards',
      buttons: 'Buttons',
    },
    labels: {
      registered: 'Registered',
      registered2: 'Registered',
      notRegistered: 'Not registered',
      noData: 'No data',
      noDate: 'No date',
      event: 'Event',
      events: 'Events',
      dates: 'Dates',
      date: 'Date',
      time: 'Time',
      venue: 'Venue',
      venue2: 'Venue',
      duplicate: 'Duplicate',
      active: 'Active',
      inactive: 'Inactive',
      archived: 'Archived',
      deleted: 'Deleted',
      finished: 'Finished',
      label: 'Label',
      eventId: 'Event ID',
      noLabel: 'No label',
      lastDay: 'Last day',
      removeTag: 'Remove tag',
      selected: 'Selected',
      notSelected: 'Not selected',
      selectedAll: 'Selected',
      notSelectedAll: 'Not selected',
      soldOut: 'Sold out!',
      noTickets: 'No tickets registered',
      new: 'New',
      site: 'Site',
      createdAt: 'Creation date',
      status: 'Status',
      ticket: 'Ticket',
      address: 'Address',
      settings: 'Settings',
      and: 'and',
      deactivated: 'Deactivated',
      undetermined: 'Undetermined',
      banks: 'Banks',
      promotion: 'Promotion',
      duration: 'Duration',
      setting: 'Setting',
      bank: 'Bank',
      quantity: 'Quantity',
      updatedAt: 'Modification date',
      createdBy: 'Created by',
      cards: 'Cards',
      card: 'Card',
      cardName: 'Card Name',
      addBank: 'Add bank',
      starts: 'Starts',
      ends: 'Ends',
      tickets: 'Tickets',
      booking: 'Booking',
      name: 'Name',
      notAvailable: 'Not available',
      sales: 'Sales',
      noOptions: 'No options',
      not_published: 'Not published',
      user: 'User',
    },
  },
  ...enBalance,
  ...enErrors,
};

export default en;
