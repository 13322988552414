export const esErrors = {
  errors: {
    errorFallback: {
      page: 'Error',
      title: '¡Vaya! Algo salió mal',
      subtitle:
        'Estamos trabajando en ello, por favor intenta de nuevo en unos minutos.',
      refresh: 'Recargar la página',
    },
    undefined: 'El servicio no está disponible, por favor intente más tarde.',
    unauthorized: 'No estás autorizado para realizar esta acción',
    database: 'Ocurrió un error en la respuesta de la base de datos:',
    FINANCE_PAYMENTS_EMPTY_FISCAL_USER_DATA:
      'Falta información fiscal del usuario en Admin',
    FINANCE_PAYMENTS_REQUEST_QUERY_STR_VALIDATION:
      'Hubo un error en la solicitud, intenta de nuevo',
    FINANCE_DECODE_ERROR: 'Error al decodificar la solicitud',
    FINANCE_PAYMENTS_MISSING_BALANCE_ID: 'Falta el ID del evento o usuario',
    FINANCE_PAYMENTS_RULE_NOT_FOUND: 'No se encontraron reglas de dispersión',
    FINANCE_PAYMENTS_SERVICE_UNKNOWN_ERROR: 'Error de servicio desconocido',
    FINANCE_PAYMENTS_RESOURCE_NOT_FOUND:
      'El recurso solicitado no fue encontrado',
    FINANCE_PAYMENTS_EVENT_NOT_FOUND: 'El evento solicitado no fue encontrado',
    FINANCE_PAYMENTS_QUERY_PROCESS:
      'Hubo un error al procesar la consulta, intenta de nuevo',
    FINANCE_PAYMENTS_SCAN_PROCESS:
      'Error al escanear la solicitud, intenta de nuevo',
    FINANCE_PAYMENTS_KAFKA_MESSAGE_NIL:
      'El mensaje de Kafka está vacío o es inválido',
    FINANCE_PAYMENTS_REQUEST_PARAM_VALIDATION:
      'Hubo un error en los parámetros de la solicitud',
    FINANCE_PAYMENTS_EVENT_ID_URL_PARAMS:
      'El ID del evento en la URL es inválido o falta',
    FINANCE_PAYMENTS_INVALID_AMOUNT:
      'El monto de la transacción es inválido o falta',
    FINANCE_PAYMENTS_MISSING_TYPE_ID:
      'El ID del tipo de transacción es inválido o falta',
    FINANCE_PAYMENTS_MISSING_TYPE_NAME:
      'El nombre del tipo de evento es inválido o falta',
    FINANCE_PAYMENTS_MISSING_EVENT_ID: 'El ID del evento es inválido o falta',
    FINANCE_PAYMENTS_MISSING_ORGANIZER_ID:
      'El ID del organizador es inválido o falta',
    FINANCE_PAYMENTS_MISSING_VALID_PERIOD:
      'El periodo de cierre es inválido o falta',
    FINANCE_PAYMENTS_MISSING_PAYMENT_AMOUNT:
      'El monto del pago es inválido o falta',
    FINANCE_PAYMENTS_PAYMENT_CREATION_ERROR:
      'Ocurrió un error inesperado al crear el pago',
    FINANCE_PAYMENTS_TRANSACTION_CREATION_ERROR:
      'Ocurrió un error inesperado al crear la transacción',
    FINANCE_PAYMENTS_TEMPLATE_NOT_FOUND: 'No se encontró la plantilla de pago',
    FINANCE_PAYMENTS_GENERATE_BALANCE_REPORT_LIB:
      'Error al generar el reporte de balance',
    FINANCE_PAYMENTS_INVALID_PAYMENT_PERCENTAGE:
      'El porcentaje de pago es inválido',
    FINANCE_PAYMENTS_INVALID_WEEKDAY: 'El día de la semana es inválido',
    FINANCE_PAYMENTS_MISSING_RULE_OWNER:
      'El propietario de las reglas falta o es inválido',
    FINANCE_PAYMENTS_MISSING_PARTIAL_CLOSING_DAYS:
      'Faltan días de cierre parcial',
    FINANCE_PAYMENTS_MISSING_FINAL_CLOSING_DAYS: 'Faltan días de cierre final',
    FINANCE_PAYMENTS_INVALID_RULE_OWNER:
      'El propietario de las reglas es inválido',
    FINANCE_PAYMENTS_MISSING_PAYMENT_ID: 'El ID del pago es inválido o falta',
    FINANCE_PAYMENTS_INVALID_CLOSING_DATE: 'La fecha de cierre es inválida',
    FINANCE_PAYMENTS_FINANCIAL_ACCOUNTANT_NOT_FOUND:
      'No se encontró al contador financiero',
    FINANCE_PAYMENTS_BALANCE_NEGATIVE: 'El balance es negativo',
    FINANCE_PAYMENTS_EVENT_HAS_NOT_CONCLUDED: 'El evento no ha concluido',
    FINANCE_PAYMENTS_EVENT_IS_NOT_OPENED: 'El evento no está abierto',
    CODE_INVALID_PAYMENT_DATE: 'La fecha de pago es inválida',
    FINANCE_PAYMENTS_GENERATE_ICT_REPORT_LIB: 'Error al generar el reporte ICT',
    FINANCE_PAYMENTS_CLOSING_NOT_EXISTS: 'El cierre no existe',
    FINANCE_PAYMENTS_MISSING_CLOSING_ID: 'Falta el ID del cierre',
    FINANCE_PAYMENTS_CLOSING_IS_PAID: 'El cierre ya fue pagado',
    FINANCE_PAYMENTS_CLOSING_CORRUPTED: 'El cierre está corrupto',
  },
};
