export const enErrors = {
  errors: {
    errorFallback: {
      page: 'Error',
      title: 'Oops! Something went wrong',
      subtitle: 'We are working on it, please try again in a few minutes.',
      refresh: 'Refresh the page',
    },
    undefined: 'The service is not available, please try again later.',
    unauthorized: 'You are not authorized to perform this action',
    database: 'An error occurred in the database response:',
    FINANCE_PAYMENTS_EMPTY_FISCAL_USER_DATA:
      'Missing fiscal information of the user in Admin',
    FINANCE_PAYMENTS_REQUEST_QUERY_STR_VALIDATION:
      'There was an error in the request, try again',
    FINANCE_DECODE_ERROR: 'Error decoding the request',
    FINANCE_PAYMENTS_MISSING_BALANCE_ID: 'Missing event or user ID',
    FINANCE_PAYMENTS_RULE_NOT_FOUND: 'No distribution rules found',
    FINANCE_PAYMENTS_SERVICE_UNKNOWN_ERROR: 'Unknown service error',
    FINANCE_PAYMENTS_RESOURCE_NOT_FOUND: 'The requested resource was not found',
    FINANCE_PAYMENTS_EVENT_NOT_FOUND: 'The requested event was not found',
    FINANCE_PAYMENTS_QUERY_PROCESS:
      'There was an error processing the query, try again',
    FINANCE_PAYMENTS_SCAN_PROCESS: 'Error scanning the request, try again',
    FINANCE_PAYMENTS_KAFKA_MESSAGE_NIL: 'The Kafka message is empty or invalid',
    FINANCE_PAYMENTS_REQUEST_PARAM_VALIDATION:
      'There was an error in the request parameters',
    FINANCE_PAYMENTS_EVENT_ID_URL_PARAMS:
      'The event ID in the URL is invalid or missing',
    FINANCE_PAYMENTS_INVALID_AMOUNT:
      'The transaction amount is invalid or missing',
    FINANCE_PAYMENTS_MISSING_TYPE_ID:
      'The transaction type ID is invalid or missing',
    FINANCE_PAYMENTS_MISSING_TYPE_NAME:
      'The event type name is invalid or missing',
    FINANCE_PAYMENTS_MISSING_EVENT_ID: 'The event ID is invalid or missing',
    FINANCE_PAYMENTS_MISSING_ORGANIZER_ID:
      'The organizer ID is invalid or missing',
    FINANCE_PAYMENTS_MISSING_VALID_PERIOD:
      'The closing period is invalid or missing',
    FINANCE_PAYMENTS_MISSING_PAYMENT_AMOUNT:
      'The payment amount is invalid or missing',
    FINANCE_PAYMENTS_PAYMENT_CREATION_ERROR:
      'An unexpected error occurred while creating the payment',
    FINANCE_PAYMENTS_TRANSACTION_CREATION_ERROR:
      'An unexpected error occurred while creating the transaction',
    FINANCE_PAYMENTS_TEMPLATE_NOT_FOUND: 'Payment template not found',
    FINANCE_PAYMENTS_GENERATE_BALANCE_REPORT_LIB:
      'Error generating balance report',
    FINANCE_PAYMENTS_INVALID_PAYMENT_PERCENTAGE:
      'The payment percentage is invalid',
    FINANCE_PAYMENTS_INVALID_WEEKDAY: 'The weekday is invalid',
    FINANCE_PAYMENTS_MISSING_RULE_OWNER:
      'The owner of the rules is missing or invalid',
    FINANCE_PAYMENTS_MISSING_PARTIAL_CLOSING_DAYS:
      'Missing partial closing days',
    FINANCE_PAYMENTS_MISSING_FINAL_CLOSING_DAYS: 'Missing final closing days',
    FINANCE_PAYMENTS_INVALID_RULE_OWNER: 'The owner of the rules is invalid',
    FINANCE_PAYMENTS_MISSING_PAYMENT_ID: 'The payment ID is invalid or missing',
    FINANCE_PAYMENTS_INVALID_CLOSING_DATE: 'The closing date is invalid',
    FINANCE_PAYMENTS_FINANCIAL_ACCOUNTANT_NOT_FOUND:
      'Financial accountant not found',
    FINANCE_PAYMENTS_BALANCE_NEGATIVE: 'The balance is negative',
    FINANCE_PAYMENTS_EVENT_HAS_NOT_CONCLUDED: 'The event has not concluded',
    FINANCE_PAYMENTS_EVENT_IS_NOT_OPENED: 'The event is not open',
    CODE_INVALID_PAYMENT_DATE: 'Invalid payment date',
    FINANCE_PAYMENTS_GENERATE_ICT_REPORT_LIB: 'Error generating ICT report',
    FINANCE_PAYMENTS_CLOSING_NOT_EXISTS: 'The closing does not exist',
    FINANCE_PAYMENTS_MISSING_CLOSING_ID: 'Missing closing ID',
    FINANCE_PAYMENTS_CLOSING_IS_PAID: 'The closing has already been paid',
    FINANCE_PAYMENTS_CLOSING_CORRUPTED: 'The closing is corrupted',
  },
};
