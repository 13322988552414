import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const balanceApi = createApi({
  reducerPath: 'balanceApi',
  refetchOnMountOrArgChange: true,
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['balances', 'user-rules', 'pause', 'balance-rules'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    // GET
    // useGetBalanceQuery
    getBalance: build.query({
      query: (eventId) => `finance/v1/payments/balances/${eventId}/rules`,
      providesTags: ['balances'],
    }),
    // useGetTransactionsQuery
    getTransactions: build.query({
      query: () => `finance/v1/payments/transactions/types`,
    }),
    // useGetBalanceRulesQuery
    getBalanceRules: build.query({
      query: (balanceID) => `finance/v1/payments/rules/balance/${balanceID}`,
      providesTags: ['balance-rules'],
    }),
    // useGetUserRulesQuery
    // useLazyGetUserRulesQuery
    getUserRules: build.query({
      query: (userId) => `finance/v1/payments/rules/user/${userId}`,
      keepUnusedDataFor: 0,
    }),
    // useGetPauseQuery
    // useLazyGetPauseQuery
    getPause: build.query({
      query: ({ type, id }) => {
        validateOptions(type, ['balances', 'users']);
        return {
          url: `finance/v1/payments/pause/${type}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['pause'],
    }),
    // POST
    // usePostBalanceRulesMutation
    postBalanceRules: build.mutation({
      query: ({ balanceID, ...body }) => ({
        url: `finance/v1/payments/rules/balance/${balanceID}`,
        body,
        method: 'POST',
      }),
    }),
    // usePostUserRulesMutation
    postUserRules: build.mutation({
      query: ({ userId, ...body }) => ({
        url: `finance/v1/payments/rules/user/${userId}`,
        body,
        method: 'POST',
      }),
    }),
    // usePostOrganizerRulesMutation
    postOrganizerRules: build.mutation({
      query: (params) => ({
        url: `finance/v1/payments/organizers/${params.eventId}/rules`,
        body: params.body,
        method: 'POST',
      }),
    }),
    // useResetBalanceMutation
    resetBalance: build.mutation({
      query: (balanceID) => ({
        url: `finance/v1/payments/rules/balance/${balanceID}/reset`,
        method: 'POST',
      }),
      invalidatesTags: ['balance-rules'],
    }),
    // usePatchUserPauseMutation
    patchUserPause: build.mutation({
      query: ({ userID, pause }) => ({
        url: `finance/v1/payments/pause/balances/${userID}`,
        params: { pause },
        method: 'PATCH',
      }),
      invalidatesTags: ['pause'],
    }),
    // [PATCH] {{Server}}/finance/v1/payments/pause/{user-id}/balance/{balance-id}?pause=false
    // usePatchBalancePauseMutation
    patchBalancePause: build.mutation({
      query: ({ userID, balanceID, pause }) => ({
        url: `finance/v1/payments/pause/${userID}/balance/${balanceID}`,
        params: { pause },
        method: 'PATCH',
      }),
      invalidatesTags: ['pause'],
    }),
  }),
});

export const {
  // GET
  useGetBalanceQuery,
  useGetTransactionsQuery,
  useGetBalanceRulesQuery,
  useGetUserRulesQuery,
  useLazyGetUserRulesQuery,
  useGetPauseQuery,
  useLazyGetPauseQuery,
  // POST
  usePostOrganizerRulesMutation,
  usePostBalanceRulesMutation,
  usePostUserRulesMutation,
  useResetBalanceMutation,
  // PATCH
  usePatchUserPauseMutation,
  usePatchBalancePauseMutation,
} = balanceApi;

function validateOptions(value, options) {
  if (!options.includes(value)) {
    throw new Error(`Invalid option: ${value}`);
  }
}

export const getPostRulesFormatted = (form) => ({
  ...form,
  partial_closing_days: [form?.partial_closing_days],
  final_closing_days: [form?.final_closing_days],
  payment_percentage: form?.payment_percentage / 100,
});

export const getDefaultRulesFormatted = (rules) => ({
  ...rules,
  partial_closing_days: rules?.partial_closing_days[0],
  final_closing_days: rules?.final_closing_days[0],
  payment_percentage: rules?.payment_percentage * 100,
});
